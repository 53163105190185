<!--
 * @Descripttion: 重命名组件
 * @version: 0.1
 * @Author: luoxi
 * @Date: 
 * @LastEditors: luoxi
 * @LastEditTime: 
 -->
<template>
  <div class="rename" v-click-outside="close">
    <el-input v-model="input" placeholder="请输入名称" @keyup.enter.native="submit"></el-input>
    <el-button icon="el-icon-check" plain @click="submit"></el-button>
    <el-button icon="el-icon-close" plain @click="close"></el-button>
  </div>
</template>

<script>
  import { dirAdd, dirUpdateName, fileUpdateName } from '@/api/file'
  export default {
    components: {

    },
    props: {
      row: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {
        input: ''
      }
    },
    watch: {

    },
    created() {

    },
    mounted() {
      this.input = this.row.name
    },
    methods: {
      // 关闭事件
      close() {
        this.$emit('close', this.row)
      },
      // 提交事件
      submit() {
        let nav = this.$store.state.file.nav
        let params = {
          name: this.input,
          parentId: nav[nav.length - 1].id
        }
        // 判断编辑还是新增
        if (this.row.id) {
          params.id = this.row.id
          if (this.row.type === 1) {
            // 文件编辑
            fileUpdateName(params)
            .then(res => {
              this.$message.success(res.msg)
              for (const i in res.data) {
                this.row[i] = res.data[i]
              }
              this.$emit('submit', this.row)
            })
          } else {
            // 文件夹编辑
            dirUpdateName(params)
            .then(res => {
              this.$message.success(res.msg)
              for (const i in res.data) {
                this.row[i] = res.data[i]
              }
              this.$emit('submit', this.row)
            })
          }
          
        } else {
          // 文件夹新增
          dirAdd(params)
          .then(res => {
            this.$message.success(res.msg)
            for (const i in res.data) {
              this.row[i] = res.data[i]
            }
            this.$emit('submit', this.row)
          })
        }
      }
      
    }
  }
</script>

<style scoped="" lang="scss">
  .rename{
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 500px;
    .el-input{
      padding-right: 10px;
      flex: 1;
      /deep/ .el-input__inner{
        height: 35px;
        box-shadow:0px 0px 6px 0px rgba(43,43,43,0.05);
        border-radius: 4px;
        padding: 0 10px;
      }
    }
    .el-button{
      padding: 9px 12px;
    }
  }
</style>
